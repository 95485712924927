import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from "styles/colors";
import dimensions from 'styles/dimensions';
import { Inner, Wrap } from 'styles/structure';

const ThesisContainer = styled(Inner)`
  > div {
    background-color: ${colors.red600};
    padding: 4rem 2.4rem 3.2rem;
  }
  
  h4 {
    color: #fff;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    > div {
      padding: 4.8rem 2.4rem;
    }
  }
`

class CaseStudyThesis extends Component {
  render() {
    return (
      <ThesisContainer>
        <div>
          <Wrap>
            <h4>{this.props.thesis}</h4>
          </Wrap>
        </div>
      </ThesisContainer>
    );
  }
}

export default CaseStudyThesis;
