import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import dimensions from 'styles/dimensions';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Helmet from "react-helmet";
import CaseStudyHero from 'components/_page/case-study/Hero';
import CaseStudyThesis from 'components/_page/case-study/Thesis';
import SliceZone from 'components/SliceZone';
import GetInTouch from 'components/GetInTouch';
import Related from 'components/Related';

const CaseStudyBody = styled.div`
  margin-top: 8rem;

  section {
    & + * {
      margin-top: 6.4rem;
    }
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 12.4rem;

    section {
      & + * {
        margin-top: 10.4rem;
      }
    }
  }
`;

const RenderBody = ({ study, site }) => (
  <>
    <CaseStudyHero
      background={study.feature_image.url}
      name={study.client_name[0].text}
      tagline={study.tagline[0].text}
      services={study.services}
      stats={study.stats}
    />

    <CaseStudyThesis thesis={study.thesis_statement} />

    {/* Body */}
    {study.body.length > 0 && (
      <CaseStudyBody>
        <SliceZone body={study.body}/>
      </CaseStudyBody>
    )}

    <GetInTouch
      title='Everything starts with a conversation.'
      signpost={(study.related_studies && study.related_studies.length > 0 && study.related_studies[0].study) ? 'View more' : null}
    />

    {(study.related_studies && study.related_studies.length > 0 && study.related_studies[0].study) && (
      <Related
        heading='See more case studies'
        type='study'
        cards={study.related_studies.slice(0, 2)}
      />
    )}
  </>
);

export default ({ data }) => {
  const study = data.prismic.studyByUID;

  if (!study) return null;

  return (
    <Layout headerStyle={'dark'}>
      <SEO
        title={study.social_title ? study.social_title : study.client_name[0].text || 'Study'}
        description={study.social_description ? study.social_description : null}
        image={study.social_image ? study.social_image.url : null}
      />

      <Helmet>
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
      </Helmet>

      <RenderBody study={study} />
    </Layout>
  );
};

export const query = graphql`
  query StudyQuery($uid: String!) {
    prismic {
      studyByUID: case_study(uid: $uid, lang: "en-us") {
        feature_image
        client_name
        tagline
        services {
          service {
            ... on PRISMIC_Service {
              name
            }
          }
        }
        stats {
          label
          value
        }
        thesis_statement
        related_studies {
          study {
            ... on PRISMIC_Case_study {
              _meta {
                uid
              }
              area {
                ... on PRISMIC_Area {
                  name
                }
              }
              client_name
              tagline
              feature_image
            }
          }
        }
        body {
          ... on PRISMIC_Case_studyBodyHighlight {
            type
            primary {
              callout
              content
            }
          }
          ... on PRISMIC_Case_studyBodyRoadblock {
            type
            primary {
              caption
              image
            }
          }
          ... on PRISMIC_Case_studyBodyImage {
            type
            primary {
              content
              image
            }
          }
          ... on PRISMIC_Case_studyBodySlideshow {
            type
            fields {
              heading
              image
              text
            }
          }
          ... on PRISMIC_Case_studyBodyPhoto_strip {
            type
            fields {
              image
              caption
            }
          }
          ... on PRISMIC_Case_studyBodyFeatured {
            type
            fields {
              link {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              source
              title
            }
            primary {
              content
            }
          }
          ... on PRISMIC_Case_studyBodyQuote {
            type
            primary {
              quote {
                ... on PRISMIC_Quote {
                  content
                  name
                  title
                }
              }
            }
          }
          ... on PRISMIC_Case_studyBodyText {
            type
            primary {
              content
              alignment
            }
          }
        }
        social_title
        social_description
        social_image
      }
    }
  }
`;
