import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from "styles/colors";
import dimensions from 'styles/dimensions';
import { Inner, Wrap } from 'styles/structure';

const HeroContainer = styled.header`
  position: relative;
  background-color: ${colors.grey800};
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  > div {
    color: #fff;
  }
`;

const HeroImage = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.32);
  }
`;

const HeroInner = styled(Inner)`
  display: flex;
  position: relative;
  flex-direction: column;
  padding-top: 12.8rem;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    min-height: 100vh;
  }
`;

const HeroWrap = styled(Wrap)`
  display: flex;
  position: relative;
  align-items: center;
  padding-bottom: 5.6rem;
  padding-left: 2.4rem;
  flex: 2 1 100%;
  
  h5 {
    margin-bottom: 1.6rem;
  }
  
  h1 {
    margin-bottom: 2.4rem;
  }
  
  li {
    position: relative;
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.72;
    
    & + * {
      margin-left: 2.4rem;
      
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0.9rem;
        left: -1.4rem;
        height: 0.4rem;
        width: 0.4rem;
        background-color: #fff;
        
      }
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-left: 0;
    
    h5 {
      margin-bottom: 2.4rem;
    }
    
    li {
      font-size: 1.8rem;
    }
  }
`;

const HeroStats = styled.div`
  position: relative;
  flex: 0 0 auto;
  padding: 4.8rem 2.4rem;
  
  > * {
    position: relative;
    z-index: 10;
  }
  
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: ${colors.red600};
  }
  
  ul {
    isolation: isolate;
  }
  
  li {
    & + * {
      margin-top: 2.4rem;
    }
  }
  
  @supports(mix-blend-mode: multiply) {
    &::before {
      mix-blend-mode: multiply;
      opacity: 1;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 4.8rem 2.4rem;
    
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }
    
    li {
      flex: 1 1 33.334%;
       
      & + * {
        margin-top: 0;
        margin-left: 6vw;
      }
    }
  }
`;

class CaseStudyHero extends Component {
  render() {
    return (
      <HeroContainer>
        {this.props.background && (
          <HeroImage>
            <img
              src={this.props.background}
              alt={this.props.name}
            />
          </HeroImage>
        )}

        <HeroInner>
          <HeroWrap>
            <div>
              {this.props.name && <h5>{this.props.name}</h5>}
              {this.props.name && <h1>{this.props.tagline}</h1>}

              {(this.props.services && this.props.services.length > 0 && this.props.services[0].service) && (
                <ul>
                  {this.props.services.map((service, i) => {
                    if (service.service && service.service.name) {
                      return <li key={i}>{service.service.name}</li>;
                    }

                    return null;
                  })}
                </ul>
              )}
            </div>
          </HeroWrap>

          {(this.props.stats && this.props.stats.length > 0) && (
          <HeroStats>
            <Wrap>
              <ul>
                {this.props.stats.map((stat, i) => (
                  <li key={i}>
                    {stat.value && <h2>{stat.value}</h2>}
                    {stat.label && <h5>{stat.label}</h5>}
                  </li>
                ))}
              </ul>
            </Wrap>
          </HeroStats>
          )}
        </HeroInner>
      </HeroContainer>
    );
  }
}

export default CaseStudyHero;
